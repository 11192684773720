<template>
  <main class="flex justify-center items-center">
    <div class="col">
      <div class="con">
        <div class="bar top"></div>
        <div class="bar middle"></div>
        <div class="bar bottom"></div>
      </div>
    </div>
    <div class="col">
      <div class="con">
        <div class="bar arrow-top"></div>
        <div class="bar arrow-middle"></div>
        <div class="bar arrow-bottom"></div>
      </div>
    </div>

    <div class="col">
      <div class="con">
        <div class="bar arrow-top-r"></div>
        <div class="bar arrow-middle-r"></div>
        <div class="bar arrow-bottom-r"></div>
      </div>
    </div>

    <div class="col">
      <div class="special-con">
        <div class="bar arrow-top-fall"></div>
        <div class="bar arrow-middle-fall"></div>
        <div class="bar arrow-bottom-fall"></div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "HamburgerNr3",


  data() {

    return {

      showNavbar : false

    }
  },



  methods : {

      showNav() {

        this.showNavbar = !this.showNavbar;

      }

  }
}
</script>

<style scoped lang="scss">


main {
  position: relative;
  top: 50%;
  height: 25vh;
  background: #0B3142;
}

.con,
.special-con {
  cursor: pointer;
  display: inline-block;
}

.bar {
  display: block;
  height: 5px;
  width: 50px;
  background: #6FFFE9;
  margin: 10px auto;
}

.con {
  width: auto;
  margin: 0 auto;
  -webkit-transition: all .7s ease;
  -moz-transition: all .7s ease;
  -ms-transition: all .7s ease;
  -o-transition: all .7s ease;
  transition: all .7s ease;
}

.con:hover .bar,.special-con:hover .bar {
  background-color: #f92c8c;
}

.col {
  display: inline-block;
  width: 24%;
  text-align: center;
  height: auto;
  position: relative;
}

.middle {
  margin: 0 auto;
}

.bar {
  -webkit-transition: all .7s ease;
  -moz-transition: all .7s ease;
  -ms-transition: all .7s ease;
  -o-transition: all .7s ease;
  transition: all .7s ease;
}

.con:hover .top {
  -webkit-transform: translateY(15px) rotateZ(45deg);
  -moz-transform: translateY(15px) rotateZ(45deg);
  -ms-transform: translateY(15px) rotateZ(45deg);
  -o-transform: translateY(15px) rotateZ(45deg);
  transform: translateY(15px) rotateZ(45deg);
}

.con:hover .bottom {
  -webkit-transform: translateY(-15px) rotateZ(-45deg);
  -moz-transform: translateY(-15px) rotateZ(-45deg);
  -ms-transform: translateY(-15px) rotateZ(-45deg);
  -o-transform: translateY(-15px) rotateZ(-45deg);
  transform: translateY(-15px) rotateZ(-45deg);
}

.con:hover .middle {
  width: 0;
}

.con:hover .arrow-top {
  -webkit-transform: rotateZ(45deg) translateY(11px);
  -moz-transform: rotateZ(45deg) translateY(11px);
  -ms-transform: rotateZ(45deg) translateY(11px);
  -o-transform: rotateZ(45deg) translateY(11px);
  transform: rotateZ(45deg) translateY(11px);
  width: 25px;
}

.con:hover .arrow-middle {
  -webkit-transform: translateX(-25px);
  -moz-transform: translateX(-25px);
  -ms-transform: translateX(-25px);
  -o-transform: translateX(-25px);
  transform: translateX(-25px);
}

.con:hover .arrow-bottom {
  -webkit-transform: rotateZ(-45deg) translateY(-11px);
  -moz-transform: rotateZ(-45deg) translateY(-11px);
  -ms-transform: rotateZ(-45deg) translateY(-11px);
  -o-transform: rotateZ(-45deg) translateY(-11px);
  transform: rotateZ(-45deg) translateY(-11px);
  width: 25px;
}

.con:hover .arrow-top-r {
  -webkit-transform: rotateZ(-45deg) translateY(11px);
  -moz-transform: rotateZ(-45deg) translateY(11px);
  -ms-transform: rotateZ(-45deg) translateY(11px);
  -o-transform: rotateZ(-45deg) translateY(11px);
  transform: rotateZ(-45deg) translateY(11px);
  width: 25px;
}

.con:hover .arrow-middle-r {
  -webkit-transform: translateX(25px);
  -moz-transform: translateX(25px);
  -ms-transform: translateX(25px);
  -o-transform: translateX(25px);
  transform: translateX(25px);
}

.con:hover .arrow-bottom-r {
  -webkit-transform: rotateZ(45deg) translateY(-11px);
  -moz-transform: rotateZ(45deg) translateY(-11px);
  -ms-transform: rotateZ(45deg) translateY(-11px);
  -o-transform: rotateZ(45deg) translateY(-11px);
  transform: rotateZ(45deg) translateY(-11px);
  width: 25px;
}

.con:hover .arrow-top-fall {
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
}

.con:hover .arrow-bottom-fall {
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transform: translateY(-15px);
}

.special-con {
  margin: 0 auto;
  -webkit-transition: all .7s ease;
  -moz-transition: all .7s ease;
  -ms-transition: all .7s ease;
  -o-transition: all .7s ease;
  transition: all .7s ease;
}

.special-con:hover .arrow-top-fall {
  -webkit-transition: all .7s ease-in-out;
  -moz-transition: all .7s ease-in-out;
  -ms-transition: all .7s ease-in-out;
  -o-transition: all .7s ease-in-out;
  transition: all .7s ease-in-out;
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
}

.arrow-bottom-fall,
.arrow-top-fall {
  -webkit-transition: all .7s ease-in-out;
  -moz-transition: all .7s ease-in-out;
  -ms-transition: all .7s ease-in-out;
  -o-transition: all .7s ease-in-out;
  transition: all .7s ease-in-out;
}

.special-con:hover .arrow-bottom-fall {
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transform: translateY(-15px);
  -webkit-transition: all .7s ease-in-out;
  -moz-transition: all .7s ease-in-out;
  -ms-transition: all .7s ease-in-out;
  -o-transition: all .7s ease-in-out;
  transition: all .7s ease-in-out;
}

.ua {
  position: absolute;
  right: 30px;
  bottom: 20px;
  color: #6FFFE9;
  font-size: 2em;
}

.fa {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.ua:hover .fa {
  color: #6FFFE9;
;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

</style>