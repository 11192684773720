<template>
<section class="py-8">
  <a  class="hamburgler cursor-pointer"
@click="showNav"
      :class="{no_hamburgler : showNavbar }"
  >
    <div class="bun top"></div>
    <div class="meat"></div>
    <div class="bun bottom"></div>
  </a>
</section>
</template>

<script>
export default {
  name: "HamburgerNr2",


  data() {

    return {

      showNavbar : false

    }
  },



  methods : {

      showNav() {

        this.showNavbar = !this.showNavbar;

      }

  }
}
</script>

<style scoped lang="scss">
// Colors

$light: #FBBF40;
$dark: #CB4B1F;
$radius: 50px;

section{
  background: $light;
  text-align: center;
  font-size: 200%;
  color: $dark;
  display: flex;
  flex-direction: column;
  height: 25%;
  width: 100%;

& > *{
    margin: auto;
  }
}

.hamburgler{
  width: 150px;
  height: 150px;
  display: block;
  transition: .5s ease all;

&.no_hamburgler{
   transform: rotate(-45deg);
 }
}

.bun,
.meat{
  display: block;
  width: 100%;
  background: $dark;
  height: 20%;
  transition: .5s ease all;
  border-radius: $radius;
}

.top{
.no_hamburgler & {
  height: 38%;
  width: 20%;
  margin-left: 40%;
  border-radius: $radius $radius 0 0;
}
}

.bottom{
  .no_hamburgler & {
  height: 38%;
  width: 20%;
  margin-left: 40%;
  border-radius: 0 0 $radius $radius;
}
}

.meat{
  margin: 20% 0;
  .no_hamburgler &{
  margin: 2% 0;

}
}

</style>