<template>

  <a class="menu-close"

     :class="{'open': showNavbar }"
     @click="showNav"
  >

    <div class="menu-close--icon">
      <span class="menu-bar"></span>
      <span class="menu-bar"></span>
    </div>

  </a>

  <a class="menu-open"

     @click="showNav"
  >
    <span class="menu-bar"></span>
    <span class="menu-bar"></span>
    <span class="menu-bar"></span>

  </a>

</template>

<script>
export default {
  name: "HamburgerNr1",


  data() {

    return {

      showNavbar : false


    }

  },



  methods : {

      showNav() {

        this.showNavbar = !this.showNavbar;

      }

  }
}
</script>

<style scoped>

:root {
  --blanc: #ffffff;
  --bleu: #284956;
  --vert: #c1bb6e;
  --pad: 140px;
  --padm: 90px;
  --pads: 60px;
  --padxs: 30px;
}


a {
  color: #284956;
}

a:link, a:visited, a:hover, a:active, a:focus, button {
  outline: 0;
}

:focus, :hover {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.menu-open {


  height: 140px;
  width:140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;

}

.menu-open::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  transition: transform .3s cubic-bezier(.35,.755,.42,.95);
  transform-origin: top left;
  transform: scaleY(0);
  background: #284956;
}

.menu-open::after {
  content: 'Menu';
  font-size: .7rem;
  color: #284956;
  position: absolute;
  bottom: 1.5rem;
  z-index: 1;
  transition: .2s;
}
.menu-open:hover::before {
  transform: scaleY(1);
}

.menu-open:hover .menu-bar {
  background: #ffffff;
}
.menu-open:hover::after {
  color: #ffffff;
}
.menu-bar:last-child {
  margin-bottom: 0;
}

.menu-bar {
  width: 42px;
  height: 1px;
  background: #284956;
  margin-bottom: 6px;
  transition: .2s;
  z-index: 1;
}
.menu-close.open {
  transform: translateY(0) ;

}

.menu-close {


position: absolute;
  height: 140px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  z-index: 20;
  transition: transform .9s cubic-bezier(.86,0,.07,.995) ;
  transform: translate3d(0,-100%,0);
}

.menu-close::before {
  background: #c1bb6e;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.menu-close .menu-bar {
  background: #284956;
  margin-bottom: 0;
}

.menu-close .menu-bar:nth-child(1) {
  transform: rotate(45deg);
}

.menu-close .menu-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.menu-close:hover .menu-close--icon {
  transform: rotate(90deg);
}

.menu-close--icon {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform .3s cubic-bezier(.35,.755,.42,.95);
}


</style>