<template>
  <section class="py-8">
  <div class="menu">
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </div>
</section>
</template>

<script>
export default {
  name: "HamburgerNr3",


  data() {

    return {

      showNavbar : false

    }
  },



  methods : {

      showNav() {

        this.showNavbar = !this.showNavbar;

      }

  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300');
section {
  height: 25%;
  width: 100%;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/escheresque_ste.png');
}
.menu {
  display: inline-block;
  width: 56px;
  top: 50%;
  left: 50%;
  transform: translate(0, 15%);
  font-size: 62px;
  font-family:'Josefin Sans', sans-serif;
  color: #fff;
  cursor: pointer;
}
.menu::before,
.menu::after {
  position: absolute;
  transition: 0.4s ease;
  opacity: 0;
  pointer-events: none;
}
.menu:before {
  content: "M \00a0 \00a0 \00a0 \00a0 \00a0U";
  top: -60px;
  left: -100%;
  width: 215px;
}
.menu:after {
  content: "N";
  top: 50px;
  left: 96%;
}
.menu:hover::before,
.menu:hover::after {
  top: 20px;
  opacity: 1;
  transition: 0.2s ease, opacity 0.17s 0.03s ease-in;
}

.bar {
  width: 80%;
  height: 3px;
  background: white;
  margin: 0 auto 18px;
  transition: transform 0.4s ease;
}


</style>