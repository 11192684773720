<template>
  <section>
  <div class="outer">
    <div class="wrapper">

      <button class="burger">
        <svg id="burger-svg" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
          <title>Show / Hide Navigation</title>
          <rect class="burger-svg__base" width="50" height="50" fill="#1f201c"/>
          <g class="burger-svg__bars" fill="#fff">
            <rect class="burger-svg__bar burger-svg__bar-1" x="14" y="18" width="22" height="2"/>
            <rect class="burger-svg__bar burger-svg__bar-2" x="14" y="24" width="22" height="2"/>
            <rect class="burger-svg__bar burger-svg__bar-3" x="14" y="30" width="22" height="2"/>
          </g>
        </svg>
      </button>

    </div>
  </div>
  </section>
</template>

<script>

import jQuery from "jquery";

import gsap from 'gsap'
const $ = jQuery;
window.$ = $;




$(function(){

  var $burger = $('.burger');
  var $bar1 = $('.burger-svg__bar-1');
  var $bar2 = $('.burger-svg__bar-2');
  var $bar3 = $('.burger-svg__bar-3');
  var isChangingState = false;
  var isOpen = false;
  var burgerTL = gsap.timeline();

  function burgerOver() {

    if(!isChangingState) {
      burgerTL.clear();
      if(!isOpen) {
        burgerTL.to($bar1, 0.5, { y: -2, ease: "elastic.easeOut" })
            .to($bar2, 0.5, { scaleX: 0.6, ease: "elastic.easeOut", transformOrigin: "50% 50%" }, "-=0.5")
            .to($bar3, 0.5, { y: 2, ease: "elastic.easeOut" }, "-=0.5");
      }
      else {
        burgerTL.to($bar1, 0.5, { scaleX: 1.2, ease: "elastic.easeOut" })
            .to($bar3, 0.5, { scaleX: 1.2, ease: "elastic.easeOut" }, "-=0.5");
      }
    }
  }

  function burgerOut() {
    if(!isChangingState) {
      burgerTL.clear();
      if(!isOpen) {
        burgerTL.to($bar1, 0.5, { y: 0, ease: "elastic.easeOut" })
            .to($bar2, 0.5, { scaleX: 1, ease: "elastic.easeOut", transformOrigin: "50% 50%" }, "-=0.5")
            .to($bar3, 0.5, { y: 0, ease: "elastic.easeOut" }, "-=0.5");
      }
      else {
        burgerTL.to($bar1, 0.5, { scaleX: 1, ease: "elastic.easeOut" })
            .to($bar3, 0.5, { scaleX: 1, ease: "elastic.easeOut" }, "-=0.5");
      }
    }
  }

  function showCloseBurger() {
    burgerTL.clear();
    burgerTL.to($bar1, 0.3, { y: 6, ease: "power4.easeIn" })
        .to($bar2, 0.3, { scaleX: 1, ease: "power4.easeIn" }, "-=0.3")
        .to($bar3, 0.3, { y: -6, ease: "power4.easeIn" }, "-=0.3")
        .to($bar1, 0.5, { rotation: 45, ease: "elastic.easeOut", transformOrigin: "50% 50%" })
        .set($bar2, { opacity: 0, immediateRender: false }, "-=0.5")
        .to($bar3, 0.5, { rotation: -45, ease: "elastic.easeOut", transformOrigin: "50% 50%", onComplete: function() { isChangingState = false; isOpen = true; } }, "-=0.5");
  }

  function showOpenBurger() {
    burgerTL.clear();
    burgerTL.to($bar1, 0.3, { scaleX: 0, ease: "back.easeIn" })
        .to($bar3, 0.3, { scaleX: 0, ease: "back.easeIn" }, "-=0.3")
        .set($bar1, { rotation: 0, y: 0 })
        .set($bar2, { scaleX: 0, opacity: 1 })
        .set($bar3, { rotation: 0, y: 0 })
        .to($bar2, 0.5, { scaleX: 1, ease: "elastic.easeOut" })
        .to($bar1, 0.5, { scaleX: 1, ease: "elastic.easeOut" }, "-=0.4")
        .to($bar3, 0.5, { scaleX: 1, ease: "elastic.easeOut", onComplete: function() { isChangingState = false; isOpen = false; } }, "-=0.5");
  }

  $burger.on('click', function() {

    if(!isChangingState) {
      isChangingState = true;

      if(!isOpen) {
        showCloseBurger();
      }
      else {
        showOpenBurger();
      }
    }

  });

  $burger.hover( burgerOver, burgerOut );

});



export default {
  name: "HamburgerNr3",


  data() {

    return {

      showNavbar : false

    }
  },



  methods : {

      showNav() {

        this.showNavbar = !this.showNavbar;

      }

  }
}
</script>

<style scoped lang="scss">
section {


  padding: 24px;
  color: white;
  background-color: #1f201c;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outer {
  width: 800px;
  height: 100%;
  max-height: 600px;
  padding: 24px;
  background-color: white;
}

.wrapper {
  position: relative;
  height: 100%;
  background-color: grey;
  background: linear-gradient(to bottom, #50A7C2 0%, #B7F8DB 100%);
}

.burger {
  z-index: 3;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  padding: 0;
  background-color: #1f201c;
  border: none;
  outline: none;
  &:hover {
    cursor: pointer;
  }
}

.isNavOpen {
  overflow: hidden;
}

</style>